import React from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Siderbar from "../Sidebar/Siderbar";
import AboutContent from "./AboutContent/AboutContent";

import { PageBodyContainer, PageContainer } from "../../styledComponents";

const About = () => {
  return (
    <PageContainer>
      <Header />
      <PageBodyContainer>
        <Siderbar />
        <AboutContent />
      </PageBodyContainer>
      <Footer />
    </PageContainer>
  );
};

export default About;
