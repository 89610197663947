import React from "react";
// REACT-ICONS
import { FaHtml5 } from "react-icons/fa";
import { FaCss3Alt } from "react-icons/fa";
import { BsBootstrap } from "react-icons/bs";
import { IoLogoJavascript } from "react-icons/io5";
import { FaReact } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { FaNodeJs } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { SiSqlite } from "react-icons/si";
// CSS-IN-JS
import {
  SkillItemsContainer,
  SkillItemContainer,
  SkillCard,
  SkillIcon,
} from "./styledComponents";

const skillList = [
  {
    id: "html5",
    icon: <FaHtml5 />,
  },
  {
    id: "css3",
    icon: <FaCss3Alt />,
  },
  {
    id: "bootstrap4.5",
    icon: <BsBootstrap />,
  },
  {
    id: "javascript",
    icon: <IoLogoJavascript />,
  },
  {
    id: "react",
    icon: <FaReact />,
  },
  {
    id: "python",
    icon: <FaPython />,
  },
  {
    id: "express.js",
    icon: <SiExpress />,
  },
  {
    id: "node.js",
    icon: <FaNodeJs />,
  },
  {
    id: "sqllite",
    icon: <SiSqlite />,
  },
];

const skillItem = (skillDetails) => {
  const { id, icon } = skillDetails;
  const displayText = id.toUpperCase();
  return (
    <SkillItemContainer key={id}>
      <SkillCard>
        <SkillIcon>{icon}</SkillIcon>
        <p>{displayText}</p>
      </SkillCard>
    </SkillItemContainer>
  );
};

const Skills = () => {
  return (
    <article>
      <SkillItemsContainer>
        {skillList.map((eachSkill) => skillItem(eachSkill))}
      </SkillItemsContainer>
    </article>
  );
};

export default Skills;
