import React from "react";
// REACT-ICONS
import { FaHome } from "react-icons/fa";
import { BsBriefcaseFill } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import { IoIosDocument } from "react-icons/io";
import { BiSolidPhoneOutgoing } from "react-icons/bi";
// CSS-IN-JS
import { NabarEl, CustomLink, NavIcon } from "./styledComponents";

const navItemsList = [
  {
    id: "/",
    icon: <FaHome />,
    displayText: "Home",
  },
  {
    id: "/portfolio",
    icon: <BsBriefcaseFill />,
    displayText: "Work",
  },
  {
    id: "/about",
    icon: <FaUserTie />,
    displayText: "About",
  },
  {
    id: "/resume",
    icon: <IoIosDocument />,
    displayText: "Resume",
  },
  {
    id: "/contact",
    icon: <BiSolidPhoneOutgoing />,
    displayText: "Contact",
  },
];

const NavItem = (navItemDetails) => {
  const { id, icon, displayText } = navItemDetails;
  const currentPath = window.location.pathname;
  return (
    <li key={id}>
      <CustomLink to={id} $active={currentPath === id}>
        <NavIcon $active={currentPath === id}> {icon}</NavIcon>
        {displayText}
      </CustomLink>
    </li>
  );
};

const Navbar = () => {
  return (
    <NabarEl>
      <ul>{navItemsList.map((eachNavItem) => NavItem(eachNavItem))}</ul>
    </NabarEl>
  );
};

export default Navbar;
