import { Button, ContentEl } from "../../../styledComponents";
import {
  Card,
  HomeCardContainer,
  Name,
  Role,
  Warning,
} from "./styledComponents";

const HomeContent = () => {
  return (
    <ContentEl>
      <HomeCardContainer className="container">
        <Card>
          <section>
            <p>Hello, My name is</p>
            <Name>Yashwanth Pendam</Name>
            <Role>MERN Full-Stack Developer</Role>
            <p>
              With a focus on MongoDB<sup>*</sup> , along with expertise in
              Express.js, React, and Node.js, I specialize in developing dynamic
              and responsive applications that enhance user engagement.
            </p>
            <Button className="mt" type="button" disabled>
              Download CV
            </Button>
          </section>
        </Card>
        <Warning>
          <sup>*</sup> - currently in the learning phase
        </Warning>
      </HomeCardContainer>
    </ContentEl>
  );
};

export default HomeContent;
