import styled from "styled-components";
import { Button } from "../../../styledComponents";

export const HomeCardContainer = styled.article`
  height: 100%;
  @media screen and (max-width: 767px) {
    padding: 0 1.6rem;
  }
`;

export const Card = styled.section`
  height: 95%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Name = styled.h2`
  font-size: 2.4rem;
  @media screen and (min-width: 768px) {
    font-size: 4rem;
  }
`;

export const Role = styled.p`
  color: ${(props) => props.theme.colors.textAccent};
  font-weight: 700;
  font-size: 2rem;
  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }
`;

export const Warning = styled.p`
  font-size: 0.9rem;
  align-self: flex-start;
  @media screen and (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const DownloadButton = styled(Button)``;
