import React from "react";

import Education from "./Education/Education";
import Skills from "./Skills/Skills";

import { ContentEl, SubHeading } from "../../../styledComponents";
import { ResumeContainer } from "./styledComponents";

const ResumeContent = () => {
  return (
    <ContentEl>
      <ResumeContainer className="container">
        <section>
          <SubHeading>Education</SubHeading>
          <Education />
        </section>
        <section>
          <SubHeading>Skills</SubHeading>
          <Skills />
        </section>
      </ResumeContainer>
    </ContentEl>
  );
};

export default ResumeContent;
