import Projects from "../Projects/Projects";

const responsiveProjectsList = [
  {
    id: "vrWebsite",
    displayText: "VR Website",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/vr-website-img.png",
    gitHubUrl: "https://github.com/PendamYashwanth/rw-vr-website",
    liveDemoUrl: "https://pendamyashwanth.github.io/rw-vr-website/",
  },
  {
    id: "ecommerce",
    displayText: "Ecommerce",
    imageUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704077232/Screenshot_2024-01-01_081414_bupe5l.png",
    gitHubUrl: "https://github.com/PendamYashwanth/rw-ecommerce",
    liveDemoUrl: "https://pendamyashwanth.github.io/rw-ecommerce/",
  },
];

const ResponsiveProjects = () => (
  <Projects projectsList={responsiveProjectsList} />
);

export default ResponsiveProjects;
