import styled from "styled-components";

export const HeaderEl = styled.header`
  background-color: ${(props) => props.theme.colors.bgPrimary};
  padding: 1.2rem 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SectionContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WebsiteLogo = styled.h1`
  text-shadow: 0.2rem 0.2rem 0.4rem ${(props) => props.theme.colors.textAccent};
`;

export const ThemeButton = styled.button`
  height: 3rem;
  width: 3rem;
  font-size: 2rem;
  color: ${(props) => props.theme.colors.textAccent};
  display: flex;
  justify-content: center;
  align-items: center;
`;
