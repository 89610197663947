import React from "react";
import Projects from "../Projects/Projects";

const staticProjectsList = [
  {
    id: "tourism",
    displayText: "Tourism",
    imageUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704079137/Screenshot_2024-01-01_084718_qihlqk.png",
    gitHubUrl: "https://github.com/PendamYashwanth/sw-tourism-website",
    liveDemoUrl: "https://pendamyashwanth.github.io/sw-tourism-website/",
  },
];

const StaticProjects = () => <Projects projectsList={staticProjectsList} />;

export default StaticProjects;
