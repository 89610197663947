import styled from "styled-components";
import { Button } from "../../../styledComponents";

export const ContactMeForm = styled.form`
  background-color: ${(props) => props.theme.colors.bgPrimary};
  padding: 1.2rem 2.4rem;
  margin: 0 2.4rem;
  box-shadow: 0.1rem 0.2rem 0.3rem
    ${(props) => props.theme.colors.textSecondary};
  border-radius: 0.8rem;
  @media screen and (min-width: 768px) {
    margin: 0;
  }
`;

export const Label = styled.label`
  text-transform: uppercase;
  font-weight: 900;
  color: ${(props) => props.theme.colors.textSecondary};
  display: block;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.8rem 1.6rem;
  height: 4rem;
  color: ${(props) => props.theme.colors.textPrimary};
  background-color: ${(props) => props.theme.colors.bgSecondary};
  /*background-color: transparent;
     box-shadow: 0rem 0rem 0.3rem ${(props) =>
    props.theme.colors.textSecondary}; */
  border-radius: 0.4rem;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 0.8rem 1.6rem;
  min-height: 5rem;
  resize: vertical;
  color: ${(props) => props.theme.colors.textPrimary};
  background-color: ${(props) => props.theme.colors.bgSecondary};
  /*background-color: transparent;
     box-shadow: 0rem 0rem 0.3rem ${(props) =>
    props.theme.colors.textSecondary}; */
  border-radius: 0.4rem;
  @media screen and (min-width: 576px) {
    min-height: 10rem;
  }
`;

export const SubmitButton = styled(Button)`
  display: block;
  width: 100%;
  max-width: 20rem;
  margin: auto;
`;
