import React from "react";
import {
  CustomProjectLink,
  ProjectImage,
  ProjectImageContainer,
  ProjectItem,
  ProjectItemHeading,
  ProjectItemsContainer,
  ProjectLinksContainer,
} from "./styledComponents";

const Projects = (props) => {
  const { projectsList } = props;
  return (
    <section className="mt">
      <ProjectItemsContainer>
        {projectsList.map((eachProject) => (
          <ProjectItem key={eachProject.id}>
            <ProjectItemHeading>{eachProject.displayText}</ProjectItemHeading>
            <ProjectImageContainer>
              <ProjectImage
                src={eachProject.imageUrl}
                alt={eachProject.displayText}
                loading="lazy"
              />
            </ProjectImageContainer>
            <ProjectLinksContainer>
              <CustomProjectLink href={eachProject.gitHubUrl} target="_blank">
                Github
              </CustomProjectLink>
              <CustomProjectLink href={eachProject.liveDemoUrl} target="_blank">
                LiveDemo
              </CustomProjectLink>
            </ProjectLinksContainer>
          </ProjectItem>
        ))}
      </ProjectItemsContainer>
    </section>
  );
};

export default Projects;
