import Bottombar from "./Bottombar/Bottombar";
import { FooterEl } from "./styledComponents";

const Footer = () => {
  return (
    <FooterEl>
      <Bottombar />
    </FooterEl>
  );
};

export default Footer;
