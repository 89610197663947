import ReactProjects from "./ReactProjects/ReactProjects";
// CSS-IN-JS
import { SubHeading } from "../../../../styledComponents";
import ResponsiveProjects from "./ResponsiveProjects/ResponsiveProjects";
import StaticProjects from "./StaticProjects/StaticProjects";
import DynamicProjects from "./DynamicProjects/DynamicProjects";

const TopProjects = () => {
  return (
    <article>
      <SubHeading>My top projects</SubHeading>
      <h3>React Projects</h3>
      <ReactProjects />
      <br />
      <h3>Dynamic Projects</h3>
      <DynamicProjects />
      <br />
      <h3>Responsive Projects</h3>
      <ResponsiveProjects />
      <br />
      <h3>Static Projects</h3>
      <StaticProjects />
    </article>
  );
};

export default TopProjects;
