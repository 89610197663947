import React from "react";

import Certifications from "./Certifications/Certifications";

import { ContentEl, SubHeading } from "../../../styledComponents";
import { AboutContainer } from "./styledComponents";

import "./AboutContent.css";

const AboutContent = () => {
  return (
    <ContentEl>
      <AboutContainer className="container">
        <section>
          <SubHeading>Biography</SubHeading>
          <p className="first-line-text-indent">
            I have honed my expertise in <strong>MERN stack development</strong>
            &nbsp; through thorough training and hands-on experience at&nbsp;
            <strong>NxtWave</strong>.&nbsp;
            <mark>
              My focus lies in crafting responsive and dynamic layouts, where I
              demonstrate excellence in building interactive user interfaces
              using <strong>React</strong>
            </mark>
            &nbsp; . Moreover, I possess adept skills in implementing
            server-side logic using <strong>Node.js</strong> and&nbsp;
            <strong>Express.js</strong>.&nbsp;
            <mark>
              While I am capable of performing CRUD operations and working with
              databases, particularly <strong>SQLlite</strong>, my proficiency
              is continually growing.
            </mark>
            &nbsp; With a strong foundation in web development and a dedicated
            commitment to creating user-friendly applications, I bring a
            distinctive skill set cultivated during my training at NxtWave.
          </p>
        </section>
        <section>
          <SubHeading>Certifications</SubHeading>
          <Certifications />
        </section>
      </AboutContainer>
    </ContentEl>
  );
};
export default AboutContent;
