import Navbar from "./Navbar/Navbar";
import { SidebarEl } from "./styledComponents";

const Siderbar = () => {
  return (
    <SidebarEl>
      <Navbar />
    </SidebarEl>
  );
};

export default Siderbar;
