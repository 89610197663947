import React from "react";

import TopProjects from "./TopProjects/TopProjects";

import { ContentEl } from "../../../styledComponents";
import { PortfolioContainer } from "./styledComponents";
import AllProjects from "./AllProjects/AllProjects";

const PortfolioContent = () => {
  return (
    <ContentEl>
      <PortfolioContainer className="container">
        <TopProjects />
        <br />
        <AllProjects />
      </PortfolioContainer>
    </ContentEl>
  );
};

export default PortfolioContent;
