import Header from "../Header/Header";
import Siderbar from "../Sidebar/Siderbar";
import Footer from "../Footer/Footer";
import { PageContainer, PageBodyContainer } from "../../styledComponents";
import PortfolioContent from "./PortfolioContent/PortfolioContent";

const Portfolio = () => {
  return (
    <PageContainer>
      <Header />
      <PageBodyContainer>
        <Siderbar />
        <PortfolioContent />
      </PageBodyContainer>
      <Footer />
    </PageContainer>
  );
};

export default Portfolio;
