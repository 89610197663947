import Header from "../Header/Header";
import Siderbar from "../Sidebar/Siderbar";
import HomeContent from "./HomeContent/HomeContent";
import Footer from "../Footer/Footer";
import { PageContainer, PageBodyContainer } from "../../styledComponents";

const Home = () => {
  return (
    <PageContainer>
      <Header />
      <PageBodyContainer>
        <Siderbar />
        <HomeContent />
      </PageBodyContainer>
      <Footer />
    </PageContainer>
  );
};

export default Home;
