import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";

const certificatesList = [
  {
    id: "static-websites",
    imgUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704360665/01static-websites_page-0001_jpabfa.jpg",
  },
  {
    id: "responsive-websites",
    imgUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704360671/02responsive-web-design_page-0001_mmb613.jpg",
  },
  {
    id: "responsive-websites-flexbox",
    imgUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704360687/03responsive-web-design-flexbox_page-0001_dujr5j.jpg",
  },
  {
    id: "python",
    imgUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704360687/04python_page-0001_ply61i.jpg",
  },
  {
    id: "dynamic-websites",
    imgUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704360679/05dynamic-web-applications_page-0001_se4nkb.jpg",
  },
  {
    id: "javascript",
    imgUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704360680/06javascript-essentials_page-0001_zru5hk.jpg",
  },
  {
    id: "developer-foundations",
    imgUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704360686/08developer-foundations_page-0001_vpzhh4.jpg",
  },
  {
    id: "database",
    imgUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704360688/07database_page-0001_bhhiyq.jpg",
  },
];

const ReactSlick = () => {
  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {certificatesList.map((eachItem) => (
          <div key={eachItem.id} className="certificates-container">
            <img
              className="certificate"
              src={eachItem.imgUrl}
              alt={eachItem.id}
              loading="lazy"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ReactSlick;
