import styled from "styled-components";

export const ProjectItemsContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  width: 100%;
`;

export const ProjectItem = styled.li`
  flex: 0 0 calc(50% - 12px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem ${(props) => props.theme.colors.textsecondary};
  @media screen and (min-width: 768px) {
    flex: 0 0 calc(50% - 12px);
  }
  @media screen and (min-width: 992px) {
    flex: 0 0 calc(33.33% - 12px);
  }
`;

export const ProjectItemHeading = styled.h4`
  padding: 1.2rem 2.4rem;
`;

export const ProjectImageContainer = styled.div`
  width: 100%;
`;

export const ProjectImage = styled.img`
  width: 100%;
`;

export const ProjectLinksContainer = styled.section`
  padding: 1.6rem 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomProjectLink = styled.a`
  cursor: pointer;
  width: max-content;
  padding: 1rem 1.2rem;
  border: 2px solid ${(props) => props.theme.colors.textSecondary};
  color: ${(props) => props.theme.colors.textPrimary};
  border-radius: 0.8rem;
  box-shadow: 0 0 0.5rem ${(props) => props.theme.colors.textsecondary};
  &:hover {
    background-color: ${(props) => props.theme.colors.textSecondary};
    color: ${(props) => props.theme.colors.bgPrimary};
  }
`;
