import Projects from "../Projects/Projects";

const reactProjectsList = [
  {
    id: "instaShare",
    displayText: "InstaShare",
    imageUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704014328/insta-share_u5jxfz.png",
    gitHubUrl: "https://github.com/PendamYashwanth/instaclone",
    liveDemoUrl: "https://yashinstashare.ccbp.tech/",
  },
  {
    id: "nxtWatch",
    displayText: "NxtWatch",
    imageUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704013712/download_1_mphjvj.png",
    gitHubUrl: "https://github.com/PendamYashwanth/nxtwatch",
    liveDemoUrl: "https://yashnxtwatch.ccbp.tech/",
  },
  {
    id: "jobbyApp",
    displayText: "Jobby App",
    imageUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704013619/jobby-app-jobs-success-lg-output-v0_zk4y1q.png",
    gitHubUrl: "https://github.com/PendamYashwanth/dw-jobby-app",
    liveDemoUrl: "https://yashjobbyapp.ccbp.tech/",
  },
];

const ReactProjects = () => <Projects projectsList={reactProjectsList} />;

export default ReactProjects;
