import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Siderbar from "../Sidebar/Siderbar";
import ResumeContent from "./ResumeContent/ResumeContent";
import { PageBodyContainer, PageContainer } from "../../styledComponents";

const Resume = () => {
  return (
    <PageContainer>
      <Header />
      <PageBodyContainer>
        <Siderbar />
        <ResumeContent />
      </PageBodyContainer>
      <Footer />
    </PageContainer>
  );
};

export default Resume;
