import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border:0;
    outline: none;
  }

html{
    font-size: 10px;
}


:root{
    --container-width-lg: 75%;
    --container-width-md: 90%;
    --container-width-sm: 100%;

    /* TRANSITION */
    --transition: all 0.3s ease;
}

::-webkit-scrollbar {
  display: none;
}


  body {
  margin: 0;
  font-family: 'Merriweather',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: ${(props) => props.theme.colors.bgSecondary};
  color:  ${(props) => props.theme.colors.textSecondary};
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1 {
  font-size: 3.2rem;
  font-family: 'Tangerine';
}

h2 {
  font-size: 2.1rem;
}

h3,h3,h4 {
  font-size: 1.8rem;
}

h6, p, a , button , input  {
  font-size: 1.6rem;
}

@media screen and (min-width: 768px){
h6, p, a , button , input  {
  font-size: 1.4rem;
}
}


h1,h2,h3,h4, h5, h6 {
  color: ${(props) => props.theme.colors.textPrimary};
}

a{
  color: ${(props) => props.theme.colors.textSecondary};
  text-decoration: none;
  transition: border-color 0.3s ease, color 0.3s ease;
}

a:hover{
  color: ${(props) => props.theme.colors.textPrimary};
}

  button{
    background-color: transparent;
    cursor: pointer;
}
  
ul{
  list-style-type: none;
}


/* =================================== MEDIA QUERIES START =================================== */
/* MEDIA QUERY FOR SMALL DEV*/
.container{
    width: var(--container-width-sm);
    max-width: 1440px;
    margin: 0 auto;
}
/* MEDIA QUERY FOR MEDIUM DEV*/
@media screen and (min-width: 768px){
    .container{
    width: var(--container-width-md);
}
}
/* MEDIA QUERY FOR LARGE DEV*/
@media screen and (min-width: 992px){
    .container{
    width: var(--container-width-lg);
}
}

/* =================================== MEDIA QUERIES END =================================== */
`;

export default GlobalStyles;
