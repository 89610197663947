import styled from "styled-components";
import { Link } from "react-router-dom";

export const NabarEl = styled.nav``;

export const NavItemsContainer = styled.ul`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
`;

export const NavItemEl = styled.li`
  width: 100%;
`;

export const NavIcon = styled.div`
  height: 2.4rem;
  width: 2.4rem;
  font-size: 2.4rem;
  color: ${(props) =>
    props.$active
      ? props.theme.colors.textAccent
      : props.theme.colors.textPrimary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CustomLink = styled(Link)`
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
