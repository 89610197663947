import React from "react";
import { SubHeading } from "../../../../styledComponents";
import {
  ProjectImage,
  ProjectImageContainer,
  ProjectItem,
  ProjectItemHeading,
  ProjectItemsContainer,
} from "../TopProjects/styledComponents";

import "./index.css";

const allProjectsList = [
  {
    id: "f680c5fb-a4d0-4f43-b356-785d920208df",
    name: "Music Page",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/music-page-img.png",
  },
  {
    id: "86f8d60b-661b-4883-b35f-3755d96da219",
    name: "Tourism Website",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/tourism-website-img.png",
  },
  {
    id: "9ed10776-db55-42ea-8bb4-634eee354b71",
    name: "Advanced Technologies",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/advanced-technologies-img.png",
  },
  {
    id: "df295d93-cc95-4672-8634-0be933e501a0",
    name: "Happy Meals",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/happy-meal-img.png",
  },
  {
    id: "38c69ad7-9d14-49fc-84df-ca694b780953",
    name: "Book Store",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/book-store-img.png",
  },
  {
    id: "a46a08ca-ca8b-42d4-8980-1702ffd4e107",
    name: "Chat Page",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/chat-screen-img.png",
  },
  {
    id: "94e9d680-41ab-4333-9db5-2d0525eba464",
    name: "Mobile Store",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/mobile-store-img.png",
  },
  {
    id: "d41955b1-ecc7-4464-917c-b07bb8c7b108",
    name: "VR Website",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/vr-website-img.png",
  },
  {
    id: "8801643d-f146-4bf4-ac26-cf6890661823",
    name: "Food Munch",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/food-munch-img.png",
  },
  {
    id: "6fcae911-14fc-4b72-9fe4-4aa77ca0f907",
    name: "Portfolio",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/portfolio-img.png",
  },
  {
    id: "a4ad754c-421d-4b7b-9f23-68676a51d3de",
    name: "Robotics Section",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/robotics-img.png",
  },
  {
    id: "5ab3af5f-2492-443f-a8a8-9738fc5966ca",
    name: "Dream Car Page",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/dream-car-img.png",
  },
  {
    id: "5f213f92-a87a-41e9-a0c4-cbb5d399e66a",
    name: "Design Section",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/design-section-img.png",
  },
  {
    id: "61df4cf1-4538-4498-8d32-33e15d683c97",
    name: "Color Palette",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/color-palette-img.png",
  },
  {
    id: "6cd6ff8a-08b8-47de-bed6-a19b91a2817b",
    name: "Product Launch Section",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/product-launch-img.png",
  },
  {
    id: "617ec598-92a3-489a-8171-7ad4b959acd1",
    name: "Weather Report Section",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/weather-report-img.png",
  },
  {
    id: "db830b52-29eb-491c-a2ee-ac350ec2cee2",
    name: "Travel Benefits",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/travel-benefits-img.png",
  },
  {
    id: "766bdd19-eb0d-4e7f-9b9e-03ecf12af99a",
    name: "Speed Type Test",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/speed-type-test-img.png",
  },
  {
    id: "3a77f7f1-0834-4214-9dec-55e63df6246f",
    name: "Random Joke Page",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/random-joke-page-img.png",
  },
  {
    id: "620a7573-bed7-44f2-a457-007cd1423694",
    name: "Sizing An Image",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/sizing-an-image-img.png",
  },
  {
    id: "189cc6e4-33ad-495b-a490-53d8213ed72f",
    name: "Seasons Switcher",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/seasons-switcher-img.png",
  },
  {
    id: "cb876e34-61fe-47ef-aaf4-59538bd70ef4",
    name: "Traffic Light",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/traffic-light-img.png",
  },
  {
    id: "e696e1a8-aac4-4478-ad8b-085ff086150a",
    name: "Tip calculator",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/tip-calculator-img.png",
  },
  {
    id: "1d076770-ee5c-40d9-9d2f-3687a391ccb6",
    name: "Chatbot",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/chat-bot-img.png",
  },
  {
    id: "c36efbaa-b509-4885-9ad6-de16c975f0b9",
    name: "Bookmark Maker",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/bookmark-img.png",
  },
  {
    id: "9a94c865-5d26-4cfa-9772-80b01eb3faa3",
    name: "Book Search",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/book-search-img.png",
  },
  {
    id: "e92bd787-6b17-468c-aac0-873d0e509be8",
    name: "Fruits Counter",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/fruits-counter-img.png",
  },
  {
    id: "b5a791fc-6395-4223-8af1-5b161652a166",
    name: "Gallery App",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/gallery-app-img.png",
  },
  {
    id: "8371d586-aab3-4b32-968c-29eb2496f7fd",
    name: "Blog List",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/blogs-list-img.png",
  },
  {
    id: "44ddf267-4a56-4c34-992d-361770a699c9",
    name: "Emoji Game",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/emoji-game-img.png",
  },
  {
    id: "84aa21e6-96d5-47f6-9911-fda6e00ecc53",
    name: "Appointments App",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/appointment-app-img.png",
  },
  {
    id: "38b968fd-8b0e-4919-a899-dc232b4f87ca",
    name: "App Store",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/app-store-img.png",
  },
  {
    id: "cac8210f-6e35-4ee6-9c98-498c5e658016",
    name: "Meme Generator",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/meme-generator-img.png",
  },
  {
    id: "add3496a-4bac-44a0-8b1d-6529ddb7525d",
    name: "Gradient Generator",
    imageUrl:
      "https://assets.ccbp.in/frontend/react-js/projects-showcase/gradient-generator-img.png",
  },
];

const AllProjects = () => {
  return (
    <article>
      <SubHeading>All Projects</SubHeading>
      <ProjectItemsContainer>
        {allProjectsList.map((eachProject) => (
          <ProjectItem key={eachProject.id}>
            <ProjectImageContainer>
              <ProjectImage
                className="all-projects-thumbnail"
                src={eachProject.imageUrl}
                alt={eachProject.name}
                loading="lazy"
              />
            </ProjectImageContainer>
            <ProjectItemHeading>{eachProject.name}</ProjectItemHeading>
          </ProjectItem>
        ))}
      </ProjectItemsContainer>
    </article>
  );
};

export default AllProjects;
