import React from "react";
// REACT-ICONS
import { FaHome } from "react-icons/fa";
import { BsBriefcaseFill } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import { IoIosDocument } from "react-icons/io";
import { BiSolidPhoneOutgoing } from "react-icons/bi";
// CSS-IN-JS
import {
  NabarEl,
  CustomLink,
  NavItemsContainer,
  NavItemEl,
  NavIcon,
} from "./styledComponents";

const navItemsList = [
  {
    id: "/",
    icon: <FaHome />,
  },
  {
    id: "/portfolio",
    icon: <BsBriefcaseFill />,
    displayText: "Work",
  },
  {
    id: "/about",
    icon: <FaUserTie />,
  },
  {
    id: "/resume",
    icon: <IoIosDocument />,
  },
  {
    id: "/contact",
    icon: <BiSolidPhoneOutgoing />,
  },
];

const NavItem = (navItemDetails) => {
  const currentPath = window.location.pathname;
  const { id, icon } = navItemDetails;
  return (
    <NavItemEl key={id}>
      <CustomLink to={id}>
        <NavIcon $active={currentPath === id}>{icon}</NavIcon>
      </CustomLink>
    </NavItemEl>
  );
};

const Bottombar = () => {
  return (
    <NabarEl>
      <NavItemsContainer>
        {navItemsList.map((eachNavItem) => NavItem(eachNavItem))}
      </NavItemsContainer>
    </NabarEl>
  );
};

export default Bottombar;
