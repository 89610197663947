import styled from "styled-components";
import { Link } from "react-router-dom";

export const NabarEl = styled.nav``;

export const NavIcon = styled.div`
  height: 2rem;
  width: 2rem;
  font-size: 2rem;
  color: ${(props) => (props.$active ? props.theme.colors.textAccent : "")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  padding: 1.2rem;
  background-color: ${(props) =>
    props.$active ? props.theme.colors.bgSecondary : ""};
  color: ${(props) => (props.$active ? props.theme.colors.textPrimary : "")};
  &:hover {
    background-color: ${(props) => props.theme.colors.bgSecondary};
    ${NavIcon} {
      color: ${(props) => props.theme.colors.textAccent};
    }
  }
`;
