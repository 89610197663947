import styled from "styled-components";

export const SidebarEl = styled.aside`
  width: 20rem;
  padding: 1.2rem 0;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.colors.bgPrimary};
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
