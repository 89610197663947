import React from "react";
import { Link } from "react-router-dom";
// REACT ICONS
import { MdSunny } from "react-icons/md";
import { BsFillMoonFill } from "react-icons/bs";
// CONTEXT
import AppContext from "../../context/AppContext";
// CSS-IN-JS
import {
  HeaderEl,
  SectionContainer,
  ThemeButton,
  WebsiteLogo,
} from "./styledComponents";

const Header = () => {
  return (
    <HeaderEl>
      <SectionContainer>
        <Link to="/">
          <WebsiteLogo>Yashwanth Pendam</WebsiteLogo>
        </Link>
        <AppContext.Consumer>
          {(value) => {
            const { isDarkTheme, toggleTheme } = value;
            const themeIcon = isDarkTheme ? <MdSunny /> : <BsFillMoonFill />;
            const onClickThemeIcon = () => {
              toggleTheme();
            };

            return (
              <ThemeButton type="button" onClick={onClickThemeIcon}>
                {themeIcon}
              </ThemeButton>
            );
          }}
        </AppContext.Consumer>
      </SectionContainer>
    </HeaderEl>
  );
};

export default Header;
