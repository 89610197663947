import React from "react";
import { ContentEl } from "../../../styledComponents";
import {
  ContactMeForm,
  Input,
  Label,
  SubmitButton,
  TextArea,
} from "./styledComponents";

import "./contactContent.css";

const ContactContent = () => {
  return (
    <ContentEl>
      <article className="container">
        <ContactMeForm>
          <section className="full-name-mail-field-container">
            <section className="form-field">
              <Label htmlFor="fullName">Full name</Label>
              <Input
                type="text"
                id="fullName"
                placeholder="Full name"
                required
              />
            </section>
            <section className="form-field">
              <Label htmlFor="mail">Email</Label>
              <Input
                type="mail"
                id="email"
                placeholder="Email address"
                required
              />
            </section>
          </section>
          <section className="form-field">
            <Label htmlFor="subject">Subject</Label>
            <Input
              type="text"
              id="subject"
              placeholder="Why you want to contact"
            />
          </section>
          <section className="form-field">
            <Label htmlFor="message">Message</Label>
            <TextArea id="message" placeholder="Type comment..." required />
          </section>
          <SubmitButton type="submit" disabled>
            Send Message
          </SubmitButton>
        </ContactMeForm>
      </article>
    </ContentEl>
  );
};

export default ContactContent;
