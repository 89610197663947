import styled from "styled-components";

/* =========================== PAGE COMPONENTS =========================== */
export const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const PageBodyContainer = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
`;

export const ContentEl = styled.main`
  flex-grow: 1;
  padding: 1rem 0;
  overflow-y: auto;
`;

/* =========================== TEXT COMPONENTS =========================== */
export const SubHeading = styled.h2`
  text-shadow: 0.1rem 0.2rem 0.3rem ${(props) => props.theme.colors.textAccent};
  margin-bottom: 1.2rem;
  &::first-letter {
    font-size: 3.2rem;
    /*     color: ${(props) => props.theme.colors.textAccent}; */
  }
`;

/* =========================== BUTTON =========================== */

export const Button = styled.button`
  width: max-content;
  padding: 1rem 1.6rem;
  border: 2px solid ${(props) => props.theme.colors.textSecondary};
  color: ${(props) => props.theme.colors.textPrimary};
  border-radius: 0.8rem;
  box-shadow: 0 0 0.5rem ${(props) => props.theme.colors.textsecondary};
  &:hover {
    background-color: ${(props) => props.theme.colors.textSecondary};
    color: ${(props) => props.theme.colors.bgPrimary};
  }
  ${(props) => props.disabled && `opacity: 0.5; cursor: not-allowed; `}
`;
