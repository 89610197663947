import Projects from "../Projects/Projects";

const dynamicProjectsList = [
  {
    id: "todoApp",
    displayText: "Todo App",
    imageUrl:
      "https://res.cloudinary.com/dhaj9wlmu/image/upload/v1704112910/Todo_App_-_Google_Chrome_01-01-2024_18_10_06_trsvch.png",
    gitHubUrl: "https://github.com/PendamYashwanth/dw-todo-app",
    liveDemoUrl: "https://pendamyashwanth.github.io/dw-todo-app/",
  },
];

const DynamicProjects = () => <Projects projectsList={dynamicProjectsList} />;

export default DynamicProjects;
