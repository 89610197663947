import React from "react";
import { v4 as uuid4 } from "uuid";
import { EducationItem } from "./styledComponents";

const educationDetailsList = [
  {
    id: uuid4(),
    institue: "Nxtwave Disruptive Technologies",
    degAndSector: "Industry Ready Certification in Full-stack Development",
    duration: "Dec 2022 - Ongoing",
  },
  {
    id: uuid4(),
    institue:
      "Jawaharlal Nehru Technological University Hyderabad University College of Engineering Manthani (JNTUHUCEM)",
    degAndSector:
      "B Tech (Bachelor of Technology)-Mechanical Engineering (ME) (6.49 CGPA)",
    duration: "2019 - 2023",
  },
  {
    id: uuid4(),
    institue: "Sri Chaitanya Junior College, Hafeezpet",
    degAndSector: "Intermediate-MPC (96.5%)",
    duration: "2017 - 2018",
  },
  {
    id: uuid4(),
    institue: "Yuva Sanghatan High School, Bellampalli",
    degAndSector: "Secondary School Of Certificate (9.2 CGPA)",
    duration: "2015 - 2016",
  },
];

const educationItem = (educationDetails) => {
  const { id, institue, degAndSector, duration } = educationDetails;
  return (
    <EducationItem key={id}>
      <p>
        <strong>{duration}</strong>
      </p>
      <h3>{institue}</h3>
      <p>{degAndSector}</p>
    </EducationItem>
  );
};

const Education = () => {
  return (
    <article>
      <ul>{educationDetailsList.map((eachItem) => educationItem(eachItem))}</ul>
    </article>
  );
};

export default Education;
