import styled from "styled-components";

export const SkillItemsContainer = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.2rem;
`;

export const SkillItemContainer = styled.li`
  flex: 0 0 calc(25% - 1.2rem);
  width: 100%;
  max-width: calc(25% - 1.2rem);
  gap: 1.6rem;
  @media screen and (max-width: 767px) {
    flex: 0 0 calc(33.33% - 1.2rem);
    max-width: calc(33.33% - 1.2rem);
  }

  @media screen and (max-width: 575px) {
    flex: 0 0 calc(50% - 1.2rem);
    max-width: calc(50% - 1.2rem);
  }
`;

export const SkillCard = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  padding: 1rem;
  width: 100%;
  border-radius: 0.8rem;
  margin: 0 auto;
  box-shadow: 0 0 0.5rem ${(props) => props.theme.colors.textsecondary};
`;

export const SkillIcon = styled.div`
  height: 4rem;
  width: 4rem;
  font-size: 4rem;
  color: ${(props) => props.theme.colors.textAccent};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
