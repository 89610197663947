import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
// THIRD PARTY PACKAGES
import { ThemeProvider } from "styled-components";
// COMPONENTS
import Home from "./components/Home/Home";
import Portfolio from "./components/Portfolio/Portfolio";
import About from "./components/About/About";
import Resume from "./components/Resume/Resume";
import Contact from "./components/Contact/Contact";
// CONTEXT
import AppContext from "./context/AppContext";
// CSS
import "./App.css";
// CSS-IN-JS
import GlobalStyles from "./globalStyles";

const LightTheme = {
  colors: {
    bgPrimary: "#fff",
    bgSecondary: "#f2f2f2",
    textPrimary: "#000",
    textSecondary: "#111",
    textAccent: "#4f89bb",
    textAccents: "#64A0FF",
  },
};

const DarkTheme = {
  colors: {
    bgPrimary: "#000",
    bgSecondary: "#111",
    textPrimary: "#fff",
    textSecondary: "#f2f2f2",
    textAccent: "#66fcf1",
  },
};

class App extends Component {
  state = { isDarkTheme: false };

  toggleTheme = () => {
    this.setState((prevState) => ({ isDarkTheme: !prevState.isDarkTheme }));
  };

  render() {
    const { isDarkTheme } = this.state;
    const currentTheme = isDarkTheme ? DarkTheme : LightTheme;
    return (
      <AppContext.Provider
        value={{ isDarkTheme, toggleTheme: this.toggleTheme }}
      >
        <ThemeProvider theme={currentTheme}>
          <GlobalStyles />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/resume" element={<Resume />} />
            <Route exact path="/contact" element={<Contact />} />
          </Routes>
        </ThemeProvider>
      </AppContext.Provider>
    );
  }
}

export default App;
